/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
html, body, div, ul, li, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ol, form, input, textarea, th, td, select {
  margin: 0;
  padding: 0;
  }
  *{box-sizing: border-box;}
  html, body {
  min-height: 100%;
  }
  
  body {
  font-family: "Microsoft YaHei";
  font-size:14px;
  color:#333;
  }
  h1, h2, h3, h4, h5, h6{font-weight:normal;}
  ul,ol {
  list-style: none;
  }
  
  img {
  border: none;
  vertical-align: middle;
  }
  
  a {
  text-decoration: none;
  color: #232323;
  }
  
  table {
  border-collapse: collapse;
  table-layout: fixed;
  }
  
  input, textarea {
  outline: none;
  border: none;
  }
  
  textarea {
  resize: none;
  overflow: auto;
  }
  
  .clearfix {
  zoom: 1;
  }
  
  .clearfix:after {
  content: ".";
  width: 0;
  height: 0;
  visibility: hidden;
  display: block;
  clear: both;
  overflow:hidden;
  }
  
  .fl {
  float: left
  }
  
  .fr {
  float: right
  }
  
  .tl {
  text-align: left;
  }
  
  .tc {
  text-align: center
  }
  
  .tr {
  text-align: right;
  }
  
  .ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  }
  .inline{
  display: inline-block;
  *display: inline;
  *zoom: 1;
  }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  
}
.title {
  text-align: left;
  font-weight: 700;
  text-indent: 0em;
}
.bold {
  text-align: left;
  font-weight: 700;
  text-indent: 2em;
}

.content{
  text-indent: 2em;
  text-align: left;
  line-height: 22px;
  padding-left: 1em;
  padding-right: 1em;
}
.time{
  text-align: left;
  line-height: 22px;
  padding-left: 1em;
  padding-right: 1em;
}
.ul{
  list-style-type:none
}
.underline{
  font-weight: 700;
  text-decoration: underline;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  
}
.title {
  text-align: left;
  font-weight: 700;
  text-indent: 0em;
}
.bold {
  text-align: left;
  font-weight: 700;
  text-indent: 2em;
}

.content{
  text-indent: 2em;
  text-align: left;
  line-height: 22px;
  padding-left: 1em;
  padding-right: 1em;
}
.time{
  text-align: left;
  line-height: 22px;
  padding-left: 1em;
  padding-right: 1em;
}
.ul{
  list-style-type:none
}
.underline{
  font-weight: 700;
  text-decoration: underline;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
    
  }
  .title {
    text-align: left;
    font-weight: 700;
    text-indent: 2em;
  }
  .time{
    text-align: left;
    line-height: 22px;
    padding-left: 1em;
    padding-right: 1em;
  }
  .bold {
    text-align: left;
    font-weight: 700;
    text-indent: 2em;
 
  }
  
  .content{
    text-indent: 2em;
    text-align: left;
    line-height: 22px;
    padding-left: 1em;
    padding-right: 1em;
  }
  .content1{
    text-indent: 2em;
    text-align: left;
    line-height: 24px;
    padding-left: 1em;
    padding-right: 1em;
  }
  .ul{
    list-style-type:none
  }
  .underline{
    font-weight: 700;
    text-decoration: underline;
  }
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .url-a{
    text-decoration: underline;

  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
    
  }
  .title {
    text-align: left;
    font-weight: 700;
    text-indent: 2em;
  }
  .time{
    text-align: left;
    line-height: 22px;
    padding-left: 1em;
    padding-right: 1em;
  }
  .bold {
    text-align: left;
    font-weight: 700;
    text-indent: 2em;
 
  }
  
  .content{
    text-indent: 2em;
    text-align: left;
    line-height: 22px;
    padding-left: 1em;
    padding-right: 1em;
  }
  .content1{
    text-indent: 2em;
    text-align: left;
    line-height: 24px;
    padding-left: 1em;
    padding-right: 1em;
  }
  .ul{
    list-style-type:none
  }
  .underline{
    font-weight: 700;
    text-decoration: underline;
  }
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .url-a{
    text-decoration: underline;

  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  .App-arrow{
    display: block;
    position: absolute;
    right:18px;
    top: 5px;
    width:12px;
    height:12px;
    background-color:#f0c032;
    transform:rotate(45deg)
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  .App-arrow{
    display: block;
    position: absolute;
    right:18px;
    top: 5px;
    width:12px;
    height:12px;
    background-color:#00c7fc;
    transform:rotate(45deg)
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
